// @import 'assets/scss/variables.scss';

// .swiper-container {
//     display: flex;
//     flex-direction: column-reverse;
// 	width: 100%;
// 	height: 100%;
// }

// .swiper-slide {
// 	/* Center slide text vertically */
// 	display: -webkit-box;
// 	display: -ms-flexbox;
// 	display: -webkit-flex;
// 	display: flex;
// 	-webkit-box-pack: center;
// 	-ms-flex-pack: center;
// 	-webkit-justify-content: center;
// 	justify-content: center;
// 	-webkit-box-align: center;
// 	-ms-flex-align: center;
// 	-webkit-align-items: center;
// 	align-items: center;
// }

// .swiper-slide img {
// 	display: block;
// 	width: 100%;
// 	height: 100%;
// 	object-fit: cover;
// }

// .swiper-pagination {
//     position: relative;
//     top: unset !important;
//     bottom: unset !important;
//     left: unset !important;
//     right: unset !important;


//     .swiper-pagination-bullet {
//         width: 24px;
//         height: 4px;
//         border-radius: 2px;
//         background-color: rgba($color: $gray2, $alpha: 0.5);
//         transition: background-color 200ms ease;
//     }
//     .swiper-pagination-bullet-active {
//         background-color: var(--bs-blue);
//     }
// }

@import 'assets/scss/variables.scss';

.swiper-button-prev,
.swiper-button-next {
    top: calc(50% - 20px);
    background-color: rgba(0,0,0,.5);

    &::after {
        font-size: 24px;
        color: #FFF;
    }

    &:hover {
        background-color: rgba(0,0,0,.75);
    }
}

.swiper-button-prev {
    left: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}

.swiper-button-next {
    right: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.swiper-slide {
    img {
        display: block;
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
    }
}

.swiper-horizontal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .swiper-wrapper {
        flex-grow: 1;
		max-height: calc(100% - 20px);
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .swiper-pagination {
        position: relative;
        width: unset;
        bottom: unset;
        left: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
		flex-shrink: 0;
        max-width: 100%;
        overflow: hidden;
        background-color: rgba(0,0,0,.25);
        padding: 6px 4px;
        border-radius: 1rem;
    }

    .swiper-pagination-bullet {
        flex-shrink: 0;
        background-color: rgba(255,255,255,.5);
    }

    .swiper-pagination-bullet-active {
        background-color: var(--bs-danger);
    }
}