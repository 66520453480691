@import 'assets/scss/variables.scss';

.img-with-title-wrapper {
    position: relative;

    .img-with-title {
        position: absolute;
        font-weight: 700;
        text-align: center;
        padding: .5rem 1rem;
        min-width: 150px;
        background: linear-gradient(109.41deg, rgba(255, 255, 255, 0.6) 6.17%, rgba(255, 255, 255, 0.1) 91.7%);
        box-shadow: 0px 4px 25px rgba(0, 0, 15, 0.25);
        backdrop-filter: blur(15px);
        border-radius: 4px;
    }
}

.welcome-section {
    min-height: 100vh;
    background-color: #000;
    background-image: url('../../../../public/public_assets/images/home/header-bg.jpg');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    @media (max-width: 767px) {
        position: relative;
        padding-top: 8rem;
        padding-bottom: 8rem;

        &::before {
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .75);
        }

        .container-xl {
            position: relative;
            z-index: 2;
        }
    }

    .welcome-title {
        font-size: 3.5rem;
        line-height: 1.5;

        @media (max-width: 767px) {
            font-size: 3rem;
        }
    }

    .welcome-description {
        font-size: 1.25rem;
    }

    .form-control {
        border-radius: .25rem;
    }
}

.benefit-section {
    .benefit-section-wrapper {
        @media (min-width: 768px) {
            background-color: #2E2E2E;
            border-radius: 1.25rem;
            padding: 2.5rem 3.75rem;

            .col-md-6:nth-child(1) {
                padding-right: 1.875rem;

                img {
                    border-radius: 1.25rem;
                }
            }

            .col-md-6:nth-child(2) {
                padding-left: 1.875rem;
            }
        }
    }

    .benefit-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        p {
            color: #CCC;
            margin-bottom: 0;
        }
    }
}

.collection-section {
    .collection-item {
        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }

        .collection-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 1.25rem;
        }
    }
}

.token-section {
    .token-section-wrapper {
        @media (min-width: 768px) {
            background-color: #2E2E2E;
            border-radius: 1.25rem;
            padding: 2.5rem 3.75rem;

            .col-md-6:nth-child(1) {
                padding-right: 1.875rem;

                img {
                    border-radius: 1.25rem;
                }
            }

            .col-md-6:nth-child(2) {
                padding-left: 1.875rem;
            }
        }
    }

    .token-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        
        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        p {
            color: #CCC;
            margin-bottom: 0;
        }
    }
}

.team-section {
    .team-member-card-wrapper {
        height: 100%;
    }
    .team-member-card {
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: .5rem;
        overflow: hidden;
        
        .team-member-card-img {
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            flex-grow: 1;
            min-height: 450px;
        }

        .team-member-card-detail {
            padding: 2.5rem 2rem;
            background-color: #2E2E2E;

            p {
                margin: 0;
                color: #CCC;
            }
        }
    }

    .team-member {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem;
        background-color: #2E2E2E;
        border-radius: .5rem;
        border: 1px solid #2E2E2E;
        cursor: pointer;
        transition: border-color 350ms ease;

        &:not(:last-child) {
            margin-bottom: 1.25rem;
        }

        &.active {
            border-color: #784BEB;
        }

        .team-member-img {
            width: 100px;
            height: 100px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 1rem;
            border-radius: .5rem;
        }

        p {
            margin: 0;
        }
    }
}

.testimonial-section {
    .sec-header {
        margin-bottom: 6rem;
        
        @media (max-width: 767px) {
            margin-bottom: 3rem;
        }
    }
    .testimonial-slider {
        position: relative;
        padding: 6rem 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 6rem;
            right: 6rem;
            background: linear-gradient(89.83deg, #274EE1 0.15%, #784BEB 52.14%, #936FEF 96.13%);
        }

        @media (max-width: 767px) {
            padding: 4rem 0;
            
            &::before{ 
                left: 2rem;
                right: 2rem;  
            }
        }
    }
    .testimonial-slide-item {
        color: #283036;
        padding: 1.5rem;
        border-radius: .75rem;
        background-color: #FFF;

        .fa-quote-right {
            color: #274EE1;
        }

        .testimonial-slide-item-footer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .testimonial-slide-item-avatar {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .testimonial-slide-item-author {
            p {
                margin: 0;
                font-weight: 700;
            }
        }
    }
}

.faq-section {
    color: #283036;
    background-color: #F4F6F8;
    background-image: url('../../../../public/public_assets/images/home/qa-bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .sec-header {
        .sec-title {
            color: #274EE1;
        }
        .sec-description {
            color: #283036;            
        }
    }

    .accordion-item {
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .accordion-button {
        font-weight: 600;
        box-shadow: none !important;
    }

    .accordion-button:not(.collapsed) {
        color: #274EE1;
        background-color: var(--bs-accordion-active-bg);
    }
}

.btn-scroll-down {
    width: 58px;
    height: 58px;
    border-radius: 50%;

    i {
        animation: UpAndDown 1s infinite ease;
    }
}

@keyframes UpAndDown {
    0%      { transform: translateY(0); }
    50%     { transform: translateY(500%); }
    100%    { transform: translateY(0); }
}