/*
Theme Name: ReactJS With Redux Templat
Author: Bao Tuan Tran
Author URI: https://baholdings.vn
Description: ReactJS With Redux Template
Version: 0.1.0
Tags:

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Alignments
# Layouts
# Header
# Sidebar
# Footer
# Content
	## Pages
	## Posts and Comments
# Media
# Responsive
# Animations
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/

@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import '~@fortawesome/fontawesome-pro/css/all.min.css';

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700;800&display=swap');

@font-face {
	font-family: 'SVN-Gilroy';
	font-weight: 700;
	font-style: normal;
	src: url('../fonts/SVN-Gilroy/SVN-Gilroy-Bold.otf') format('opentype');
}
@font-face {
	font-family: 'SVN-Gilroy';
	font-weight: 600;
	font-style: normal;
	src: url('../fonts/SVN-Gilroy/SVN-Gilroy-Semibold.otf') format('opentype');
}
@font-face {
	font-family: 'SVN-Gilroy';
	font-weight: 500;
	font-style: normal;
	src: url('../fonts/SVN-Gilroy/SVN-Gilroy-Medium.otf') format('opentype');
}
@font-face {
	font-family: 'SVN-Gilroy';
	font-weight: 400;
	font-style: normal;
	src: url('../fonts/SVN-Gilroy/SVN-Gilroy-Regular.otf') format('opentype');
}

body {
	font-family: 'Plus Jakarta Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #FFF;
	background-color: #1A1A1A;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	word-wrap: normal;
	font-weight: 800;
	max-width: 100%;
}
p {
	word-wrap: break-word;
	max-width: 100%;
	
	a {
		font-weight: 600;
		color: inherit;

		&:hover, &:focus {
			color: var(--primary);
		}
	}
}

/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
a {
	text-decoration: none;
}
a, button {
	cursor: pointer;
	outline: none !important;
}
.btn {
	font-weight: 700 !important;
	border-radius: .25rem;

	&.btn-lg {
		font-size: var(--bs-body-font-size) !important;
		padding: 1rem;
	}
}
a, button, .btn, .dropdown-item, .nav-link {
	i {
		margin-right: 8px;
	}
}
.btn-icon i {
	margin-right: 0 !important;
}
.btn-icon-right i {
	margin-right: 0;
	margin-left: 8px;
}
.btn-rounded {
	border-radius: 1.5rem;
}
.btn-link {
	padding: 0;
	text-decoration: none !important;

	&:focus {
		box-shadow: none;
	}
}
.btn-primary {
	color: #FFF !important;
	background: linear-gradient(89.83deg, #274EE1 0.15%, #784BEB 52.14%, #936FEF 96.13%);
	border: none !important;
}

.btn-190 {
	max-width: 100%;
	min-width: 190px;
}

ul, ol {
	padding-inline-start: 17px;
}

.dropdown-menu {
	.dropdown-item {
		&:focus {
			background-color: var(--bs-primary);
		}
	}
}

.text-gradient-primary {
	background: linear-gradient(89.83deg, #274EE1 0.15%, #784BEB 52.14%, #936FEF 96.13%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
.form-label {
	font-weight: 600;
}
.form-control {
	border: none !important;
	border-radius: .25rem !important;
	background-color: var(--bs-gray-200) !important;

	&::placeholder {
		color: var(--bs-gray-500);
	}

	&.form-control-lg {
		font-size: var(--bs-body-font-size) !important;
		padding: 1rem;
	}

	&.form-control-transparent {
		color: #FFF;
		border: 1px solid rgba(255, 255, 255, 1) !important;
		background-color: transparent !important;
	}
}
.input-group {
	.form-control {
		&:focus, &.active {
			box-shadow: none !important;
		}
	}
	.input-group-text {
		border: none !important;
		padding-left: 12px;
		padding-right: 12px;
		color: var(--bs-blue) !important;
		background-color: var(--bs-white) !important;
		transition: box-shadow 0.15s ease-in-out;
		
		&.form-solid {
			color: var(--bs-white) !important;
			background-color: rgba($color: var(--bs-blue), $alpha: 0.5) !important;
			border-color: rgba($color: var(--bs-blue), $alpha: 0.5) !important;
		}
	}
}

/*--------------------------------------------------------------
# Navigation
	## Links
--------------------------------------------------------------*/
button.nav-link {
	border: none;
    background: none;
    width: 100%;
    text-align: inherit;
}

/*--------------------------------------------------------------
# Navigation
	## Menus
--------------------------------------------------------------*/
.navbar-toggler {
	border-color: transparent !important;
	padding: .35rem .25rem;
	box-shadow: none !important;
}

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
.site {
	position: relative;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.sec-header {
	margin-bottom: 3.5rem;

	.sec-title {
		font-size: 2.25rem;
		font-weight: 700;
		margin-bottom: .25rem;
		color: var(--bs-primary);

		&:last-child {
			margin-bottom: 0;
		}
	}
	.sec-description {
		font-weight: 700;
		color: #FFF;
		margin: auto;
		margin-bottom: 0;
		max-width: 820px;

		&:not(:last-child) {
			margin-bottom: .5rem;
		}

		&:not(:first-of-type) {
			color: #CCC;
		}
	}
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.site-copyright {
	padding-top: 1rem;
	padding-bottom: 1rem;
	text-align: center;

	p {
		margin-bottom: 0;
		font-weight: 600;
	}
}


/*--------------------------------------------------------------
# Content
	## Pages
--------------------------------------------------------------*/
.page-section {
	position: relative;
	padding-top: 5rem;
	padding-bottom: 5rem;

	@media (max-width: 767px) {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}
}

/*--------------------------------------------------------------
# Content
	## Posts and Comments
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Responsive
--------------------------------------------------------------*/
@media (max-width: 767px) {
	.desktop {
		display: none !important;
	}
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes fadeIn {
	0%      { opacity: 0; }
	100%    { opacity: 1; }
}